import * as Sentry from "@sentry/react";
import React from "react";
import {
  useLocation,
  useNavigationType,
  createRoutesFromChildren,
  matchRoutes,
} from "react-router-dom";

export const sentryInit = () => {
  const { VITE_SENTRY_DSN, VITE_SENTRY_ENV } = import.meta.env;

  if (!VITE_SENTRY_DSN || !VITE_SENTRY_ENV) return;

  Sentry.init({
    dsn: VITE_SENTRY_DSN,
    environment: VITE_SENTRY_ENV,
    integrations: [
      new Sentry.BrowserTracing({
        // See docs for support of different versions of variation of react router
        // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
        routingInstrumentation: Sentry.reactRouterV6Instrumentation(
          React.useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes,
        ),
      }),
      new Sentry.Replay(),
    ],
    release: import.meta.env.VITE_GIT_SHA,

    replaysOnErrorSampleRate: 1.0,

    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0.1,

    // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [
      "localhost",
      /^https:\/\/network\.aws\.joinstorkclub\.com\/api/,
      /^https:\/\/network\.joinstorkclub\.com\/api/,
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    tracesSampleRate: 1.0,
  });
};
